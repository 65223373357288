/* eslint-disable @typescript-eslint/camelcase */
import {
  EventResponse,
  ProductResponse,
  ProductWithConfig,
} from '@/interfaces/product.interface'
import {
  CreateTicketData,
  SellerReport,
  Ticket,
  TicketResponse,
} from '@/interfaces/ticket.interface'
import {
  MySalesSeparateTotalsApiResponse,
  Total,
} from '@/interfaces/seller/sales.interface'

import { UserResponse } from '@/interfaces/user.interface'
import { Vue } from 'vue-property-decorator'

import axios from '@/services/base.service'

const resource = 'sellers'

export const getProductsBySeller = async (params): Promise<ProductResponse> => {
  const { limit, page, eventId, categoryId } = params

  const {
    data: { data, meta, links, configs },
  } = await axios.get<ProductResponse>(`${resource}/products`, {
    params: {
      per_page: limit ?? 10,
      page: page ?? 1,
      event_id: eventId,
      category: categoryId,
    },
  })
  return { data, meta, links, configs }
}

export const getProductsOfEvent = async (
  eventId,
  page?,
  query?: string,
  category_id?: number,
  seller_id?: number,
  ecommerce: string = 'both'
): Promise<ProductResponse> => {
  const {
    data: { data, meta, links, configs },
  } = await axios.get<ProductResponse>(
    `/v2/products/events/${eventId}?page=${page}`,
    {
      params: {
        sellers: true,
        q: query ?? null,
        category_id: category_id ?? null,
        seller_id: seller_id ?? null,
        ecommerce: ecommerce,
      },
    }
  )
  return { data, meta, links, configs }
}

export const getProductsOfEventVip = async (
  eventId,
  page?
): Promise<ProductResponse> => {
  const {
    data: { data, meta, links, configs },
  } = await axios.get<ProductResponse>(
    `/v2/products/events/vip/${eventId}?page=${page}`
  )
  return { data, meta, links, configs }
}

export const getProductSellers = async (productId: number): Promise<any> => {
  const data = await axios.get(`${resource}/products/${productId}/sellers`)
  return { data }
}

export const getProductSellersVIP = async (productId: number): Promise<any> => {
  const data = await axios.get(`${resource}/products-vip?event_id=${productId}`)
  return { data }
}

export const exportTicketsByseller = async (): Promise<any> => {
  const data = await axios.get(`${resource}/`)
  return { data }
}

export const getEventDetails = async (
  eventId: number | string,
  with_permissions = false,
  with_config = false
): Promise<EventResponse> => {
  const {
    data: { data, meta, links },
  } = await axios.get<EventResponse>(
    `${resource}/events/${eventId}?with_permissions=${with_permissions}&with_config=${with_config}`
  )
  return { data, meta, links }
}

export const getUpcomingEventsBySeller = async (
  page?: number,
  limit?: number,
  query?: string,
  business_id?: number
): Promise<EventResponse> => {
  const {
    data: { data, meta, links },
  } = await axios.get<EventResponse>(`${resource}/events`, {
    params: {
      per_page: 100,
      page: page ?? 1,
      q: query ?? null,
      business_id: business_id ?? null,
      with_permissions: true,
    },
  })
  return { data, meta, links }
}

export const getPastEventsBySeller = async (
  page?: number,
  limit?: number,
  query?: string,
  business_id?: number
): Promise<EventResponse> => {
  const {
    data: { data, meta, links },
  } = await axios.get<EventResponse>(`${resource}/events-past`, {
    params: {
      per_page: 100,
      page: page ?? 1,
      q: query ?? null,
      business_id: business_id ?? null,
      with_permissions: true,
    },
  })
  return { data, meta, links }
}

export const getCategoriesBySeller = async (
  eventId?: number,
  page?: number,
  limit?: number
): Promise<any> => {
  const { data } = await axios.get(`/categories`, {
    params: {
      per_page: limit ?? 10,
      page: page ?? 1,
      event: eventId,
    },
  })
  return data
}

export const getCategoriesBySellerVip = async (
  eventId?: number,
  page?: number,
  limit?: number
): Promise<any> => {
  const { data } = await axios.get(`/categories/vip`, {
    params: {
      per_page: limit ?? 10,
      page: page ?? 1,
      event: eventId,
    },
  })
  return data
}

export const getTicketsBySeller = async (
  eventId: number,
  page?: number,
  limit?: number,
  filters?: any,
  allTickets?: boolean
): Promise<TicketResponse> => {
  const {
    data: { data, links, meta },
  } = await axios.get<TicketResponse>(`${resource}/tickets`, {
    params: {
      per_page: limit ?? 10,
      page: page ?? 1,
      event_id: eventId,
      reference: filters.reference,
      category: filters.category,
      product: filters.product,
      seller: filters.seller,
      full_quota: filters.full_quota,
      allTickets: allTickets ?? false,
      with_titular: true,
    },
  })
  return { data, links, meta }
}

export const getTicketsBySellerVip = async (
  eventId: number,
  page?: number,
  limit?: number,
  filters?: any
): Promise<TicketResponse> => {
  const {
    data: { data, links, meta },
  } = await axios.get<TicketResponse>(`${resource}/tickets/vip`, {
    params: {
      per_page: limit ?? 10,
      page: page ?? 1,
      event_id: eventId,
      reference: filters.reference,
      category: filters.category,
      product: filters.product,
      full_quota: filters.full_quota,
    },
  })
  return { data, links, meta }
}

export const getReportByEvent = async (
  eventId: number
): Promise<SellerReport[]> => {
  const response = await axios.get<SellerReport[]>(`${resource}/report`, {
    params: {
      event: eventId,
    },
  })
  return response.data
}

export const getProductInformation = async (
  uuid: string
): Promise<ProductWithConfig> => {
  const {
    data: { data, configs },
  } = await axios.get<ProductWithConfig>(`/products/${uuid}`)
  return { data, configs }
}

export const getTicketInfo = async (uuid: string): Promise<Ticket> => {
  const { data } = await axios.get(`/tickets/${uuid}`)
  return data
}

export const deleteTicketId = async (id: string): Promise<Ticket> => {
  const { data } = await axios.delete(`${resource}/tickets/${id}`)
  return data
}

export const deleteTicketAssistantId = async (id: string): Promise<Ticket> => {
  const { data } = await axios.delete(`/assistants/${id}/delete`)
  return data
}

export const getAssistantsOfEvent = async (
  eventId: number,
  page?: number,
  limit?: number,
  q?: string,
  verified?: boolean
): Promise<EventResponse> => {
  const {
    data: { data, meta, links },
  } = await axios.get(`${resource}/events/${eventId}/assistants`, {
    params: {
      per_page: limit ?? 10,
      page: page ?? 1,
      q: q ?? null,
      verified: verified ?? false,
    },
  })
  return { data, meta, links }
}

export const getAssistantData = async (
  assistantId,
  userRequest = false
): Promise<any> => {
  const url = !userRequest
    ? `/assistants/${assistantId}`
    : `/assistants/${assistantId}?user=true`
  const data = await axios.get(url)
  return data
}

export const getUserByDni = async (dni): Promise<any> => {
  const data = await axios.get(`assistants/search/${dni}`)
  return data
}

export const generateTicket = async (
  ticketData: CreateTicketData
): Promise<Ticket> => {
  const formData = new FormData()

  formData.append('product_id', ticketData.product_id.toString())
  formData.append('quantity_persons', ticketData.quantity_persons.toString())
  formData.append('payment_status', ticketData.payment_status.toString())
  formData.append('payment_method', ticketData.payment_method.toString())
  formData.append('reference', ticketData.reference.toString())

  const { data } = await axios.post(`/tickets/generate`, formData)

  return data
}

export const editTicket = async (
  ticketData: CreateTicketData,
  ticketId: number
): Promise<Ticket> => {
  const formData = new URLSearchParams()

  formData.append('product_id', ticketData.product_id.toString())
  formData.append('quantity_persons', ticketData.quantity_persons.toString())
  formData.append('payment_status', ticketData.payment_status.toString())
  formData.append('payment_method', ticketData.payment_method.toString())
  formData.append('reference', ticketData.reference.toString())

  const { data } = await axios.put(`/tickets/${ticketId}/update`, formData, {
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
  })

  return data
}

export const getSellersOfProduct = async (
  id: string
): Promise<ProductResponse> => {
  const { data } = await axios.get(`${resource}/products/${id}/sellers`)
  return data
}

export const getRecepcionistsOfEvent = async (eventId): Promise<any> => {
  const data = await axios.get(`${resource}/event/${eventId}/recepcionists`)
  return data
}

export const getAllRecepcionists = async (
  event_id?,
  page?,
  query?
): Promise<any> => {
  const {
    data: { data, meta, links },
  } = await axios.get(`/recepcionist?page=${page}`, {
    params: {
      q: query ?? null,
      event_id: event_id ?? null,
    },
  })
  return { data, meta, links }
}

export const attachRecepcionistToEvent = async (
  event_id,
  recepcionist_id
): Promise<any> => {
  const data = await axios.post(`/events/${event_id}/attach-recepcionist`, {
    recepcionist_id: recepcionist_id,
  })
  return data
}

export const detachRecepcionistFromEvent = async (
  event_id,
  recepcionist_id
): Promise<any> => {
  const data = await axios.post(`/events/${event_id}/detach-recepcionist`, {
    recepcionist_id: recepcionist_id,
  })
  return data
}

export const getRecepcionist = async (recepcionist_id): Promise<any> => {
  const data = await axios.get(`/recepcionist/${recepcionist_id}`)
  return data
}

export const createRecepcionist = async (body): Promise<any> => {
  const data = await axios.post(`/recepcionist`, body)
  return data
}

export const updateRecepcionist = async (
  recepcionist_id,
  body
): Promise<any> => {
  const data = await axios.put(`/recepcionist/${recepcionist_id}`, body)
  return data
}

export const getPetitions = async (count: boolean): Promise<any> => {
  const data = await axios.get(`/petitions`, {
    params: {
      count: count ?? false,
    },
  })
  return data
}

export const getMyPetitions = async (count: boolean): Promise<any> => {
  const data = await axios.get(`/my-petitions`, {
    params: {
      count: count ?? false,
    },
  })
  return data
}

export const updatePetition = async (petitionId, body): Promise<any> => {
  const data = await axios.put(`/petitions/${petitionId}`, body)
  return data
}

export const postPetition = async (body): Promise<any> => {
  const data = await axios.post(`/petitions`, body)
  return data
}

export const getAllSellers = async (
  page?,
  query?,
  state?,
  per_page?
): Promise<UserResponse> => {
  const {
    data: { data, meta, links },
  } = await axios.get<UserResponse>(`${resource}?page=${page}`, {
    params: {
      q: query ?? null,
      state: state ?? null,
      per_page: per_page ?? 25,
    },
  })
  return { data, meta, links }
}

export const getProductOfSeller = async (sellerId): Promise<any> => {
  const {
    data: { data, meta, links },
  } = await axios.get<any>(`${resource}/show-events-with-products/${sellerId}`)
  return { data, meta, links }
}

export const publishEventEcommerce = async (eventId, slug): Promise<any> => {
  const body = {
    event_id: eventId,
    slug: slug,
  }
  const data = await axios.post(`${resource}/publish-event-ecommerce`, body)
  return data
}

export const deleteProduct = async (id: string): Promise<any> => {
  const data = await axios.delete(`${resource}/products/${id}`)
  return data
}

export const getProductOfSeller2 = async (sellerId): Promise<any> => {
  const {
    data: { data, meta, links },
  } = await axios.get<any>(
    `${resource}/get-suscribed-events-with-products/${sellerId}`
  )
  return { data, meta, links }
}

export const getUserById = async (userId): Promise<any> => {
  const data = axios.get<any>(`${resource}/get-seller/${userId}`)

  return data
}

export const getLocations = async (business_id): Promise<any> => {
  const data = axios.get<any>(`/locations`, {
    params: {
      business_id: business_id,
    },
  })

  return data
}

export const postSeller = async (body): Promise<any> => {
  const data = axios.post<any>(`${resource}/create`, body)
  return data
}

export const putSeller = async (userId: number, body: any): Promise<any> => {
  const data = axios.put<any>(`${resource}/edit/${userId}`, body)

  return data
}

export const reassignSeller = async (ticketUuid: string, sellerId: number): Promise<any> => {
  const body = {
    seller_id: sellerId,
  }
  
  const data = axios.put<any>(`${resource}/tickets/${ticketUuid}/reassign-seller`, body)
  return data
}

export const cloneProduct = async (product_id): Promise<any> => {
  const data = axios.get<any>(`/v2/products/clone/${product_id}`)

  return data
}

export const shareTicketByMail = async (body): Promise<any> => {
  const data = axios.post<any>(`${resource}/send-ticket`, body)

  return data
}

export const getPricingOfEvent = async (event_id): Promise<any> => {
  const data = axios.get<any>(`/pricing/${event_id}`)

  return data
}

export const createEventPricing = async (body): Promise<any> => {
  const data = axios.post<any>(`/pricing/create`, body)

  return data
}

export const updateEventPricing = async (
  body: any,
  id: number
): Promise<any> => {
  const data = axios.put<any>(`/pricing/${id}/update`, body)

  return data
}

export const getAssistantsExcel = async (eventId: number): Promise<any> => {
  return axios
    .get<any>(`${resource}/assistants/export/${eventId}`, {
      responseType: 'blob',
    })
    .then(response => {
      console.log(response)
      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(new Blob([response.data]))

      link.setAttribute('download', 'Asistentes.xlsx')

      document.body.appendChild(link)

      link.click()
    })
    .catch(error => {
      console.error(error)
      Vue.$toast.error('Ha ocurrido un problema descargando el recurso')
      return false
    })
}

export const getProductsExcel = async (eventId: number): Promise<any> => {
  return axios
    .get<any>(`${resource}/ticket-with-vip-products/${eventId}`, {
      responseType: 'blob',
    })
    .then((response: any) => {
      console.log(response)
      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(new Blob([response.data]))

      link.setAttribute('download', 'ProductsVip.xlsx')

      document.body.appendChild(link)

      link.click()
    })
    .catch(error => {
      Vue.$toast.error('Ha ocurrido un problema descargando el recurso')
      return false
    })
}

export const getAllBusiness = async (): Promise<any> => {
  const data = axios.get<any>(`${resource}/business`)

  return data
}

export const getAllEventsSuperAdmin = async (
  page?: number,
  limit?: number,
  paid?: string,
  business_id?: number
): Promise<EventResponse> => {
  const {
    data: { data, meta, links },
  } = await axios.get<EventResponse>(`/pricing/events`, {
    params: {
      per_page: limit ?? 10,
      page: page ?? 1,
      paid: paid ?? null,
      business_id: business_id ?? null,
    },
  })
  return { data, meta, links }
}

export const getAllEventsSelectSuperAdmin = async (): Promise<any> => {
  const data = axios.get<any>(`/seller-report/events`)
  return data
}

export const putEvent = async (eventId, body): Promise<any> => {
  const data = await axios.put(`/pricing/${eventId}`, body)
  return data
}

export const getAllEventsSimplified = async (): Promise<any> => {
  const data = axios.get<any>(`/seller-report/events`)

  return data
}

export const getTicketStatsSuperAdmin = async (
  event_id?: number,
  business_id?: number,
  from?: any,
  to?: any
): Promise<any> => {
  const data = axios.get<any>(`/seller-report`, {
    params: {
      event_id: event_id ?? null,
      business_id: business_id ?? null,
      from: from ?? null,
      to: to ?? null,
    },
  })

  return data
}

export const disableProduct = async (productId: number): Promise<any> => {
  const data = await axios.put(`/v2/products/disable/${productId}`)
  return data
}

export const getTicketsExcel = async (eventId: number): Promise<any> => {
  return axios
    .get<any>(`/tickets/export/${eventId}`, {
      responseType: 'blob',
    })
    .then(response => {
      console.log(response)
      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(new Blob([response.data]))

      link.setAttribute('download', 'Tickets.xlsx')

      document.body.appendChild(link)

      link.click()
    })
    .catch(error => {
      console.error(error)
      Vue.$toast.error('Ha ocurrido un problema descargando el recurso')
      return false
    })
}

export const getEventsWithProducts = async (sellerId, page) => {
  let pagination_page = page != false ? page : 1
  const response = await axios.get(
    `/sellers/show-events-with-products/${sellerId}?page=${pagination_page}`
  )
  return {
    data: response.data.data,
    page: response.data.current_page,
    total: response.data.last_page,
  }
}

export const addProduct = async (productId, sellerId) => {
  const { data } = await axios.post(
    `/sellers/attach-seller-to-products/${sellerId}/${productId}`
  )
  return data
}

export const removeProduct = async (productId, sellerId) => {
  const { data } = await axios.post(
    `/sellers/detach-seller-to-products/${sellerId}/${productId}`
  )
  return data
}

export const getInactiveEventsBySeller = async (
  page?: number,
  limit?: number,
  query?: string,
  business_id?: number
): Promise<EventResponse> => {
  const {
    data: { data, meta, links },
  } = await axios.get<EventResponse>(`${resource}/events-inactive`, {
    params: {
      per_page: limit ?? 10,
      page: page ?? 1,
      q: query ?? null,
      business_id: business_id ?? null,
      with_permissions: true,
    },
  })
  return { data, meta, links }
}

export const updateProductStatus = async (product_id, status): Promise<any> => {
  const data = await axios.put(`/v2/products/change-status/${product_id}`, {
    status: status,
  })
  return data
}
export const getSellersOfEvent = async eventId => {
  const { data } = await axios.get(`/events/${eventId}/sellers`)
  return data
}

export const refundEcommerceMCPTicket = async body => {
  const data = await axios.post(`/tickets/refund-ecommerce-mcp`, body)
  return data
}

export const getTicketWithTransaction = async (event_id, ticket_uuid) => {
  const data = await axios.get(
    `/tickets/with-transaction/${event_id}/${ticket_uuid}`
  )
  return data
}

export const getProductsMinimalByEvent = async (event_id: number) => {
  const data = await axios.get(`/products/get-minimal/${event_id}`)
  return data
}

export const getHistoryChangesOfProduct = async (product_id: number) => {
  const data = await axios.get(`/products/history-changes/${product_id}`)
  return data
}

export const getAllPdfTicket = async (
  page = 1,
  event_id = 0,
  client = '',
  payment_method = '',
  sortBy = 'created_at',
  sortDesc = true
) => {
  const data = await axios.get(
    `/v2/reports/ecommerce/ticket/pdf?page=${page}&event=${event_id}&client=${client}&payment_method=${payment_method}&sortBy=${sortBy}&sortDesc=${sortDesc}`
  )
  return data
}

export const getTransactionMCP = async (page = 1, event_id = 0) => {
  const data = await axios.get(
    `/v2/reports/events/get/settlement-report?page=${page}&event=${event_id}`
  )
  return data
}

export const generateCodeForExclusiveProduct = async (body: any) => {
  const data = await axios.post(`/security-phrase/generate`, body)
  return data
}

/**
 * Retrieves the separated totals for a given event ID.
 * @param eventId - The ID of the event.
 * @returns A promise that resolves to an array of Total objects.
 */
export const getSeparatedTotals = async (eventId: number): Promise<Total[]> => {
  const { data } = await axios.get<MySalesSeparateTotalsApiResponse>(
    `v2/reports/${eventId}/my-sales/separated-totals`
  )

  return data.totals
}

export const getSellerByProduct = async (product_id: number) => {
  const { data } = await axios.get(`/automation/products/${product_id}/sellers`)
  console.log(data)

  return data.data
}

export const getLocationsWhereUserIsBoss = async () => {
  const data = await axios.get(`/v2/sellers/get-locations-where-boss`)
  return data;
}

export const generateTicketAnonymus = async (
  productId: number,
  ticketsQuantity: number,
  sellerId: number
) => {
  const { data } = await axios.post(`/automation`, {
    product_id: productId,
    quantity: ticketsQuantity,
    seller_id: sellerId,
  })

  console.log(data);
  return data.data;

}
